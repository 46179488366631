// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCG1gYdPzw7BnP5hmknq8dlACv17CqAHoI",
  authDomain: "lifehunt-329ed.firebaseapp.com",
  projectId: "lifehunt-329ed",
  storageBucket: "lifehunt-329ed.appspot.com",
  messagingSenderId: "178825002555",
  appId: "1:178825002555:web:3351013d04190edecea61e",
  measurementId: "G-2MYRK2JB6P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)

export {db}
export const auth = getAuth();