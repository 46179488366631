

const JobsHeader = () =>
{
    return(
       
        <div className="jobs-header-wrapper">
             <br></br>
        <h2 style={{textAlign:"center", fontSize:"40px", color:"rgb(231, 129, 21)", fontWeight:"bolder"}
    }>Choose the Right Job For You</h2>
        <p style={{textAlign:"center"}}>Get Referral from Employees Working in your Dream Company</p>
        </div>
    )
}

export default JobsHeader