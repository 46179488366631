

const DashboardHeader = () =>
{
    return(
        <div className="jobs-header-wrapper">
        <h1 style={{textAlign:"center", fontSize:"40px", color:"rgb(231, 129, 21)", fontWeight:"bolder"}
    }>Referral Dashboard</h1>
        
        </div>
    )
}

export default DashboardHeader