
const ReferralHeader = () =>
{
    return(
        <div className="jobs-header-wrapper">
        <h1 style={{textAlign:"center", fontSize:"40px", color:"rgb(231, 129, 21)", fontWeight:"bolder"}
    }>Lifehunt Referral Program</h1>
        <p style={{textAlign:"center"}}>Get Referral from Employees Working in your Dream Company</p>
        </div>
    )
}

export default ReferralHeader